import React, {useRef, useState} from 'react';
import axios from 'axios';

const baseURL = `https://tiktok-downloader-server.dcstyle.info`;

function App() {
	const videoUrlInputRef = useRef();

	const [url, setUrl] = useState('');
	const [downloadUrl, setDownloadUrl] = useState(null);
	const [videoData, setVideoData] = useState(null);
	const [loading, setLoading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [error, setError] = useState('');

	const clearSubmit = (e) => {
		e.preventDefault();

		setVideoData(null);

		setDownloadUrl(null);

		setLoading(false);
		setProgress(0);

		setError('');

		videoUrlInputRef.current.reset();
	}

	const handleSubmit = async (e) => {
		e.preventDefault();

		setVideoData(null);

		setDownloadUrl(null);

		setLoading(true);
		setProgress(0);

		setError('');

		if (!url) {
			setError('Please enter a URL');
			setLoading(false);
			return;
		}

		try {
			const response = await axios.post(
				`${baseURL}/api/download`,
				{
					'url': url
				},
				{
					headers: { "Content-Type": "application/json" },
					onDownloadProgress: progressEvent => {
						const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
						setProgress(percentCompleted);
					}
				}
			);

			const result = response.data.result,
				videoData = response.data.videoData;

			if (videoData === null)
			{
				setError('Failed to fetch video. Please check the URL and try again.');
			} else {
				setVideoData(videoData);

				if (result.videoUrl)
				{
					setDownloadUrl(result.videoUrl);
				} else {
					setDownloadUrl(result.imageUrls);
				}
			}

			setLoading(false);
		} catch (error) {
			setError('Failed to fetch video. Please check the URL and try again.');
			setLoading(false);
		}
	};

	return (
		<div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
			<header className="w-full py-4 bg-blue-600 text-white text-center">
				<h1 className="text-3xl">TikTok Video Downloader</h1>
			</header>

			<main className="flex-grow flex flex-col items-center justify-center w-full max-w-7xl">
				<form onSubmit={handleSubmit} className="w-full" ref={videoUrlInputRef}>
					<input
						type="text"
						value={url}
						onChange={(e) => setUrl(e.target.value)}
						placeholder="https://www.tiktok.com/@hacoi1711/video/7367178376399965448"
						className="w-full p-3 border border-gray-300 rounded mb-4 hover:border-blue-500 focus:border-blue-500"
					/>
					<button type="submit" className="w-full p-3 bg-blue-600 text-white rounded hover:bg-blue-700">
						Submit
					</button>
				</form>

				{error && <p className="text-red-500 mt-4">{error}</p>}

				{loading && (
					<div className="mt-4 w-full">
						<div className="relative pt-1">
							<div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-blue-200">
								<div style={{ width: `${progress}%` }} className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-blue-500 transition-all duration-500"></div>
							</div>
							<p className="text-center">{progress}%</p>
						</div>
					</div>
				)}

				{(videoData && downloadUrl) && (
					<div className="mt-4 w-full p-5 border border-gray-300 rounded mb-4">
						<div className="flex justify-between items-center mb-4">
							<div>
								<h2 className="text-lg font-semibold">{videoData.description}</h2>

								<div className="flex items-center space-x-4">
									<span>❤️ {videoData.stats.digg_count}</span>
									<span>💬 {videoData.stats.comment_count}</span>
									<span>🔗 {videoData.stats.share_count}</span>
								</div>
							</div>
							<img src={videoData.thumbnail_url} alt="Thumbnail"
							     className="w-24 h-24 object-cover rounded-lg"/>
						</div>

						<a href={downloadUrl}
						   target="_blank" rel="noopener noreferrer"
						   className="block p-3 bg-green-600 text-white rounded mb-2 text-center hover:bg-green-700 hover:outline-0 hover:border-0">
							Download (No Watermark)
						</a>

						<a href={videoData.urlNoWatermark}
						   target="_blank" rel="noopener noreferrer"
						   className="block p-3 bg-green-600 text-white rounded mb-2 text-center hover:bg-green-700 hover:outline-0 hover:border-0">
							Download (Original)
						</a>

						<button className="block w-full p-3 bg-gray-600 text-white rounded text-center hover:bg-gray-700 hover:outline-0 hover:border-0"
						        onClick={clearSubmit}>
							Download Another Video
						</button>
					</div>
				)}
			</main>

			<footer className="w-full py-4 bg-gray-800 text-white text-center">
				<p>Created by <a href="https://hoangcongdat.dev/" target="_blank" className="underline text-blue-500">dcstylexf</a></p>
			</footer>
		</div>
	);
}

export default App;
